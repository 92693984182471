<template>
  <div class="header-overlay header-overlay-svg">
      <img src="/ext_files/prepaid/f2g/images/logotype_dark.svg">
      <svg
        v-on:click="goBack"
        class="close-overlay"
        style="cursor:pointer;"
        width="30px"
        height="30px"
        viewBox="0 0 30 30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <desc>Created with Sketch.</desc>
        <g id="Icon/Menu/close" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path
            d="M4.38605846,25.6177075 C4.64303181,25.8725692 4.97774286,26 5.31247602,26 C5.6471926,26 5.98188707,25.8725692 6.23889359,25.6177075 L15,16.8528625 L23.7633218,25.6177075 C24.0202952,25.8725692 24.3528502,26 24.6897394,26 C25.024456,26 25.3591505,25.8725692 25.616157,25.6177075 C26.1279477,25.1058277 26.1279477,24.2764407 25.616157,23.7645498 L16.8528372,15.0019165 L25.6139457,6.23707158 C26.1257364,5.72519181 26.1257364,4.89580481 25.6139457,4.38391398 C25.102155,3.87202315 24.2729123,3.8720342 23.7611106,4.38391398 L15,13.1487589 L6.2366823,4.38612565 C5.7248916,3.87424587 4.89564893,3.87424587 4.38384717,4.38612565 C3.87204541,4.89800542 3.87205647,5.72739242 4.38384717,6.23928325 L13.1471669,15.0019165 L4.38605846,23.7667615 C3.87426776,24.2786413 3.87426776,25.1058719 4.38605846,25.6177627 L4.38605846,25.6177075 Z"
            id="Path"
            fill="#FFFFFF"
            fill-rule="nonzero"
          />
        </g>
      </svg>
    </div>
</template>

<script>

import store from '@/store/index'

export default {
  name: "OverlayHeader",
  methods: {
    goBack() {
      store.commit("showLoginPrompt", false);
      store.commit("showActivateBundle", false);
      store.commit("showSuccessfulActivation", false);
      store.commit("showFailedActivation",false);
      store.commit("showTopUpPrompt",false);
    }
  },
  created(){
    document.body.classList.add("opened-overlay");
  },
  destroyed(){
    document.body.classList.remove("opened-overlay");
  },
};
</script>
