<template>
    <div class="bundles-homepage">

        <div class="list-bundles row row-f2g">
            <template v-for="bundle in activeBundleGroup.bundles">
                <BundleCard :instance="bundle" :key="bundle.assetId"></BundleCard>
            </template>
        </div>
        <div class="button-bundles-see-all">
             <a :href="seeAllBundleListHref">
                  <div class="container-button blue-btn">
                    Δες όλα τα πακέτα                        
                 </div> 
             </a> 
        </div>
        <router-view />

    </div>
</template>

<script>

import store from '@/store/index'
import BundleCard from '@/components/BundleCard'

export default {
  name: 'MainBundleView',
  components: {
      BundleCard
  },
  computed: {
    locale() {
      return store.state.locale;
    },
    username() {
      return store.state.username;
    },
    bundlesGroups() {
        return store.state.bundlesGroups;
    },
    activeBundleGroup() {
        return store.state.activeBundleGroup;
    },
    seeAllBundleListHref() {
      if (this.locale == 'en_US') {
        return '/en/f2g/bundles/';
      } else {
        return '/gr/f2g/paketa/';
      }
    }
  },
  data() {
    return {

    }
  },
  beforeMount() {

  },
  methods: {
  }
}
</script>