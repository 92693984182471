<template>
  <div class="wrapper-overlay open-overlay">
    <OverlayHeader />
    <div class="container-overlay overlay-form">
        <div class="overlay-message">
            <img src="/ext_files/prepaid/f2g/images/Success@2x.png" class="logo-overlay" />
            <div class="title-overlay1">
                {{ words['myf2g.bundles.login.prompt.title'] }}
            </div>
            <div class="title-overlay2">
                {{ words['myf2g.bundles.login.prompt.subtitle'] }}
            </div>

            <div class="overlay-button-container">
              <a @click="goToLoginPage">
                <div class="button-overlay container-button blue-btn">
                      {{ words['myf2g.bundles.login.prompt.login'] }}                             
                </div>
              </a>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
import OverlayHeader from "@/components/OverlayHeader.vue"
import store from '@/store/index'

export default {
  name: "LoginPrompt",
  components: {
    OverlayHeader
  },
  data() {
    return {
      words: {}
    };
  },
  created() {

  },
  beforeMount() {
    if ( sessionStorage.getItem("wcs_myf2bundlesloginprompt_"+this.locale) != null ) {
      this.words = JSON.parse(sessionStorage.getItem("wcs_myf2bundlesloginprompt_"+this.locale));
    } else {
      

        let config = {
            method: 'get',
            url: '/gr/f2g/vueContentService/',
            params: {
                ciKey: 'myf2bundlesloginprompt',
                locale: this.locale,
            }
        }
      
      let self = this;
      this.axios(config).then(function(response){
        console.log(response);
        self.words = response.data;
        sessionStorage.setItem("wcs_myf2bundlesloginprompt_"+self.locale, JSON.stringify(self.words));

      }).catch(function(error){
        console.error(error);
      })

    }
  },
  computed: {
    locale() {
        return store.state.locale;
    },
    username() {
        return store.state.username;
    },
    msisdn() {
        return store.state.msisdn
    },
    selectedBundle() {
        return store.state.selectedBundle;
    }
  },
  methods: {
    setCookie(name,value,days) {
      var expires = "";
      if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (encodeURIComponent(value) || "")  + expires + "; path=/";
    },
    goToLoginPage() {
      sessionStorage.setItem("BundleInfo", JSON.stringify(this.selectedBundle))
      this.setCookie("prepaidDL", window.location.href, 1);
      var loginURL = "";
      if (this.locale == 'en_US') {
        loginURL = '/en/f2g/my-free-2-go/login/#/credentials'
      } else {
        loginURL = '/gr/f2g/my-free-2-go/login/#/credentials'
      }
      window.location.href = loginURL;
    },
  }
};
</script>