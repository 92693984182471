<template>
  <div class="wrapper-overlay open-overlay">
    <OverlayHeader />
    <div class="container-overlay overlay-form">
        <div class="overlay-message">
            <img src="/ext_files/prepaid/f2g/images/Success@2x.png" class="logo-overlay" />
            <div class="title-overlay1">
                {{ words['myf2g.bundles.success.modal.title'] }}
            </div>
            <div class="title-overlay2" v-html="selectedBundle.positiveFeedback"></div>
            <div class="overlay-button-container">
              <a :href="goToDashboard">
                <div class="button-overlay container-button blue-btn">
                      {{ words['myf2g.bundles.success.modal.button'] }}                           
                </div>
              </a>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
import OverlayHeader from "@/components/OverlayHeader.vue"
import store from '@/store/index'

export default {
  name: "SuccessfulActivation",
  components: {
    OverlayHeader
  },
  data() {
    return {
      words: {}
    };
  },
  created() {

  },
  beforeMount() {
    if ( sessionStorage.getItem("wcs_myf2bundlessuccessmodal_"+this.locale) != null ) {
      this.words = JSON.parse(sessionStorage.getItem("wcs_myf2bundlessuccessmodal_"+this.locale));
    } else {
      

      let config = {
            method: 'get',
            url: '/gr/f2g/vueContentService/',
            params: {
                ciKey: 'myf2bundlessuccessmodal',
                locale: this.locale,
            }
        }
      
      let self = this;
      this.axios(config).then(function(response){
        console.log(response);
        self.words = response.data;
        sessionStorage.setItem("wcs_myf2bundlessuccessmodal_"+self.locale, JSON.stringify(self.words));

      }).catch(function(error){
        console.error(error);
      })

    }
  },
  computed: {
    locale() {
        return store.state.locale;
    },
    goToDashboard() {
      if (this.locale == 'en_US') {
        return '/en/myf2g/'
      } else {
        return '/gr/myf2g/'
      }
    },
    username() {
        return store.state.username;
    },
    msisdn() {
        return store.state.msisdn
    },
    selectedBundle() {
      return store.state.selectedBundle;
    }
  },
  methods: {
    
  }
};
</script>