import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showLoader: false,
    locale: '',
    isDevMode: false,
    username: '',
    msisdn: '',
    isLoggedIn: false,
    isOtpUser: false,
    bundlesGroups: [],
    activeBundleGroup: {},
    activeSecBundleGroup: {},
    selectedBundle: {},
    paylaterBundle: null,
    hasPaylaterBundle: false,
    errorTitle: '',
    errorSubtitle: '',
    getBundlesSemaphore: false,
    showLoginPrompt: false,
    showActivateBundle: false,
    showSuccessfulActivation: false,
    showFailedActivation: false,
    showTopUpPrompt: false,
    showPayLaterModal: false,
    areBundlesLoaded: false
  },
  mutations: {
    showLoader(state, data) {
      state.showLoader = data;
    },
    locale(state, data) {
      state.locale = data;
    },
    isDevMode(state, data){
      state.isDevMode = data;
    },
    username(state, data) {
      state.username = data;
    },
    msisdn(state, data) {
      state.msisdn = data;
    },
    isLoggedIn(state, data) {
      state.isLoggedIn = data;
    },
    isOtpUser(state, data) {
      state.isOtpUser = data;
    },
    bundlesGroups(state, data) {
      state.bundlesGroups = data;
    },
    activeBundleGroup(state, data) {
      state.activeBundleGroup = data;
    },
    activeSecBundleGroup(state, data) {
      state.activeSecBundleGroup = data;
    },
    selectedBundle(state, data) {
      state.selectedBundle = data;
    },
    paylaterBundle(state, data){
      state.paylaterBundle = data;
    },
    hasPaylaterBundle(state, data){
      state.hasPaylaterBundle = data;
    },
    errorTitle(state, data) {
      state.errorTitle = data;
    },
    errorSubtitle(state, data) {
      state.errorSubtitle = data;
    },
    getBundlesSemaphore(state, data) {
      state.getBundlesSemaphore = data;
    },
    showLoginPrompt(state, data) {
      state.showLoginPrompt = data;
    },
    showActivateBundle(state, data) {
      state.showActivateBundle = data;
    },
    showSuccessfulActivation(state, data) {
      state.showSuccessfulActivation = data;
    },
    showFailedActivation(state, data){
      state.showFailedActivation = data;
    },
    showTopUpPrompt(state, data) {
      state.showTopUpPrompt = data;
    },
    showPayLaterModal(state, data) {
      state.showPayLaterModal = data;
    },
    areBundlesLoaded(state, data) {
      state.areBundlesLoaded = data;
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
  }
})