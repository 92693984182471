import Vue from 'vue'
import _ from 'lodash';
import VueRouter from 'vue-router'
import store from '../store/index'
import axios from 'axios'


Vue.use(VueRouter)


const router = new VueRouter({})


router.beforeEach((to, from, next) => {

  if (_.isEmpty(store.state.bundlesGroups) && !store.state.getBundlesSemaphore) {
    
    store.commit("getBundlesSemaphore", true);

    //build request
    let request = {
      caller: {
          callingSystem: "myF2Gweb",
          salesCode: "",
          username: document.getElementById('user-info').getAttribute('data-username') || 'anonymous',
          locale: document.getElementsByTagName('body')[0].getAttribute('data-locale') || 'el_GR',
          channel: "F2G"
      }
    }

    //build axios config
    let config = {
      method: 'post',
      url: '/apiv2/bundles/getBundles/',
      data: request
    }

    store.commit("showLoader",true);
    
    axios(config).then(function(response){

      if (response.data.errorLevel === 0) {
        store.commit("bundlesGroups", response.data.bundleGroups);
  
        store.state.bundlesGroups.forEach(function(bundleGroup){
        
          if (bundleGroup.assetId == "1526319530399") {
            store.commit("activeBundleGroup", bundleGroup);
          }

          if (bundleGroup.assetId == "1526321079406") {
            store.commit("activeSecBundleGroup", bundleGroup);
          }
      
        })

        store.commit("areBundlesLoaded", true);

      
      }


    }).catch(function(error){
      console.error(error);
    }).finally(function(){
      store.commit("showLoader",false);
    })

  } else {
  
      store.state.bundlesGroups.forEach(function(bundleGroup){
      
        if (bundleGroup.assetId == "1526319530399") {
          store.commit("activeBundleGroup", bundleGroup);
        }

        if (bundleGroup.assetId == "1526320900932" || bundleGroup.assetId == "1526319530399") {
          store.commit("activeSecBundleGroup", bundleGroup);
        }
    
      })
  
    }

  next();
  
  
})

export default router