<template>
  <div>
    <div class="container-f2g black-container extra-padding-hp">
        <div class="f2g-wind-container extra-padding-hp"> 
                
            <div class="mini-title-blue-center"> 
                {{ words["homepage.bundle.minititle"] }}
            </div>
              
            <div class="large-title hidden-xs hidden-sm">
              {{ words["homepage.bundle.title"] }}
            </div>		
              
            <div class="large-title hidden-md hidden-lg">
              {{ words["homepage.bundle.title"] }}
            </div>

            <div class="f2g-wind-container" v-if="areBundlesLoaded">
            
              <MainBundleView/>

            </div>
            
        </div>
      </div>
      <div class="container-f2g light-blue-container extra-padding-hp separator-top-right">
        <div class="f2g-wind-container extra-padding-hp"> 
                
            <div class="mini-title-blue-center"> 
              {{ words["homepage.bundle2.minititle"] }}
            </div>
              
            <div class="large-title hidden-xs hidden-sm">
              {{ words["homepage.bundle2.title"] }}
            </div>		
              
            <div class="large-title hidden-md hidden-lg">
              {{ words["homepage.bundle2.title"] }}
            </div>

            <div class="f2g-wind-container" v-if="areBundlesLoaded">
            
              <SecondBundleView/>

            </div>
            
        </div>
      </div>
      <Loader/>

      <LoginPrompt v-if="showLoginPrompt"/>
      <ActivateBundle v-if="showActivateBundle"/>
      <SuccessfulActivation v-if="showSuccessfulActivation"/>
      <FailedActivation v-if="showFailedActivation"/>
      <TopUpPrompt v-if="showTopUpPrompt"/>
      <PayLaterModal v-if="showPayLaterModal"/>
</div>
</template>

<script>

import store from '@/store/index'
import MainBundleView from '@/views/MainBundleView'
import SecondBundleView from '@/views/SecondBundleView.vue'
import Loader from '@/components/Loader'
import LoginPrompt from '@/overlays/LoginPrompt'
import ActivateBundle from '@/overlays/ActivateBundle'
import SuccessfulActivation from '@/overlays/SuccessfulActivation'
import FailedActivation from '@/overlays/FailedActivation'
import TopUpPrompt from '@/overlays/TopUpPrompt'
import PayLaterModal from './overlays/PayLaterModal'

import _ from 'lodash';

export default {
  name: 'App',
  components: {
    MainBundleView,
    SecondBundleView,
    Loader,
    LoginPrompt,
    ActivateBundle,
    SuccessfulActivation,
    FailedActivation,
    TopUpPrompt,
    PayLaterModal
  },
  computed: {
    areBundlesLoaded() {
      return store.state.areBundlesLoaded;
    },
    locale() {
      return store.state.locale;
    },
    username() {
      return store.state.username;
    },
    bundlesGroups() {
      return store.state.bundlesGroups;
    },
    getBundlesSemaphore() {
      return store.state.getBundlesSemaphore;
    },
    showLoginPrompt() {
      return store.state.showLoginPrompt;
    },
    showActivateBundle() {
      return store.state.showActivateBundle;
    },
    showSuccessfulActivation() {
      return store.state.showSuccessfulActivation
    },
    showFailedActivation() {
      return store.state.showFailedActivation
    },
    showTopUpPrompt() {
      return store.state.showTopUpPrompt;
    },
    showPayLaterModal() {
      return store.state.showPayLaterModal;
    }

  },
  data() {
    return {
      words: {}
    }
  },
  beforeMount() {

    

    //Initiate app vars
    this.initAppVars();

    if ( sessionStorage.getItem("wcs_myf2bundlehomepage_"+this.locale) != null ) {
      this.words = JSON.parse(sessionStorage.getItem("wcs_myf2bundlehomepage_"+this.locale));
    } else {
      
      let config = {
        method: 'get',
        url: '/gr/f2g/vueContentService/',
        params: {
          ciKey: 'myf2bundlehomepage',
          locale: this.locale,
        }
      }
      
      let self = this;
      this.axios(config).then(function(response){
        console.log(response);
        self.words = response.data;
        sessionStorage.setItem("wcs_myf2bundlehomepage_"+self.locale, JSON.stringify(self.words));

      }).catch(function(error){
        console.error(error);
      })

    }

    //call get Bundles()
    if (_.isEmpty(this.bundlesGroups) && !this.getBundlesSemaphore) {
      store.commit("getBundlesSemaphore", true);
      this.getBundles();
    }

    if (this.readCookie("prepaidDL") !== null) {
      if (document.getElementById('user-info').getAttribute('data-is-logged-in') == "true") {
        // if istopup redirect and dont show prompt
        var bundleInfo = JSON.parse(sessionStorage.getItem("BundleInfo"));
        if(bundleInfo.isTopUpBundle){
          this.redirectForTopUp(bundleInfo.topUpAmount, bundleInfo.bankDeepLinking);
        }else
        this.redirectActivateBundle();
      } else {
        sessionStorage.removeItem("BundleInfo");
        this.deleteCookie("prepaidDL");
      }
    }
    

  },
  methods: {
    initAppVars() {
      let locale = document.getElementsByTagName('body')[0].getAttribute('data-locale') || 'el_GR';
      let isDevMode = document.getElementsByTagName("BODY")[0].getAttribute("data-devmode") == "true" || false;

      let username = document.getElementById('user-info').getAttribute('data-username') || 'anonymous';
      let msisdn = document.getElementById('user-info').getAttribute('data-msisdn');
      let isLoggedIn = (document.getElementById('user-info').getAttribute('data-is-logged-in') == "true") || false;
      let isOtpUser = (document.getElementById('user-info').getAttribute('data-otpuser') == "true") || false;

      store.commit('locale', locale);
      store.commit("isDevMode", isDevMode);
      
      store.commit('username', username);
      store.commit('msisdn', msisdn);
      store.commit('isLoggedIn', isLoggedIn);
      store.commit("isOtpUser", isOtpUser);
    },
    getBundles() {

      //build request
      let request = {
        caller: {
            callingSystem: "myF2Gweb",
            salesCode: "",
            username: this.username,
            locale: this.locale,
            channel: "F2G"
        }
      }

      //build axios config
      let config = {
        method: 'post',
        url: '/apiv2/bundles/getBundles/',
        data: request
      }

      //make axios call
      let self = this;
      
      store.commit("showLoader",true);
      this.axios(config).then(function(response){


        if (response.data.errorLevel === 0) {
        store.commit("bundlesGroups", response.data.bundleGroups);
  
        store.state.bundlesGroups.forEach(function(bundleGroup){
        
          if (bundleGroup.assetId == "1526319530399") {
            store.commit("activeBundleGroup", bundleGroup);
          }

          if (bundleGroup.assetId == "1526321079406") {
            store.commit("activeSecBundleGroup", bundleGroup);
          }
      
        })

        store.commit("areBundlesLoaded", true);

      
      }


      }).catch(function(error){
        console.error(error);
      }).finally(function(){
        store.commit("showLoader",false);
      })

    },
    readCookie(cookieName) {
      var nameEQ = cookieName + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
    },
    deleteCookie(cookieName) {
      if (this.readCookie(cookieName) !== null) {
        document.cookie = cookieName + "=" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
      }
    },
    redirectActivateBundle () {
        var bundleInfo = JSON.parse(sessionStorage.getItem("BundleInfo"));
        sessionStorage.removeItem("BundleInfo");
        this.deleteCookie("prepaidDL");
        store.commit("selectedBundle", bundleInfo);
        store.commit("showActivateBundle", true);
    },
    redirectForTopUp (topUpAmount, bankDeepLinking){
      var url;
      var params="";
      if (topUpAmount){
          params="?amount="+topUpAmount+"&bankDeepLinking="+bankDeepLinking
      }
      if (this.locale == "en_US") {
          url = "/en/f2g/upiresies/online-ananeosi-hronou-omilias/online-ananeosi-hronou-omilias-meso-myf2g-/"+params;
      } else {
          url = "/gr/f2g/upiresies/online-ananeosi-hronou-omilias/online-ananeosi-hronou-omilias-meso-myf2g-/"+params;
      }
      sessionStorage.removeItem("BundleInfo");
      this.deleteCookie("prepaidDL");
      window.location.href = url;
    }
  }
}
</script>