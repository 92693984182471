<template>
  <div class="wrapper-overlay open-overlay">
    <OverlayHeader />

    <div class="container-overlay overlay-form">
        <div class="overlay-message">
            <img src="/ext_files/prepaid/f2g/images/question@2x.png" class="logo-overlay" />
            <div class="title-overlay1">
                {{ words['myf2g.bundles.activation.modal.title'] }}
            </div>
            <div class="title-overlay2">
                {{ words['myf2g.bundles.activation.modal.info.part1'] }} {{selectedBundle.title}} {{ words['myf2g.bundles.activation.modal.info.part2'] }} {{msisdn}}<template v-if="!isOtpUser"> {{ words['myf2g.bundles.activation.modal.info.part3'] }} {{username}}</template>{{locale == "en_US" ? "?" : ";"}}
            </div>
            <div class="overlay-button-container">
                <div class="container-button blue-btn" style="cursor:pointer; margin:20px;">
                    <a v-on:click="activateBundle()">{{ words['myf2g.bundles.activation.modal.activation'] }} </a>                               
                </div>
                <div class="container-button  transparent-btn" style="cursor:pointer; margin:20px;">
                    <a v-on:click="cancelActivation()" >{{ words['myf2g.bundles.activation.modal.cancel'] }}</a>                               
                </div>

            </div>
        </div>
    </div>

    <Loader/>

  </div>
</template>


<script>
import OverlayHeader from "@/components/OverlayHeader.vue"
import Loader from '../components/Loader'
import store from '@/store/index'

export default {
  name: "ActivateBundle",
  components: {
    OverlayHeader,
    Loader
  },
  data() {
    return {
        words: {}
    };
  },
  beforeMount() {
    if ( sessionStorage.getItem("wcs_myf2bundleactivationmodal_"+this.locale) != null ) {
        this.words = JSON.parse(sessionStorage.getItem("wcs_myf2bundleactivationmodal_"+this.locale));
    } else {
    

        let config = {
            method: 'get',
            url: '/gr/f2g/vueContentService/',
            params: {
                ciKey: 'myf2bundleactivationmodal',
                locale: this.locale,
            }
        }
        
        let self = this;
        this.axios(config).then(function(response){
            console.log(response);
            self.words = response.data;
            sessionStorage.setItem("wcs_myf2bundleactivationmodal_"+self.locale, JSON.stringify(self.words));

        }).catch(function(error){
            console.error(error);
        })

    }
  },
  created() {

  },
  computed: {
    isDevMode() {
        return store.state.isDevMode;
    },
    locale() {
        return store.state.locale;
    },
    username() {
        return store.state.username;
    },
    msisdn() {
        return store.state.msisdn
    },
    selectedBundle() {
        return store.state.selectedBundle;
    },
    selectedBundleGroup(){
        return store.state.activeBundleGroup;
    },
    isOtpUser() {
        return store.state.isOtpUser;
    }
  },
  methods: {
      cancelActivation() {
        store.commit("showActivateBundle", false);
      },
      activateBundle() {
          console.log("bundle activation called");

            //build request
            var request = {
                caller: {
                    callingSystem:"myF2Gweb",
                    salesCode:"",
                    username: this.username,
                    data:"",
                    locale: this.locale,
                    channel:"F2G"
                },
                id: this.selectedBundle.onlineBundleId,
                checkRemainingBalance: true,
                syncActivation: true
            }

            var config = {
                method: "post",
                url: "/apiv2/prepaidcustomer/activateBundleProcess",
                data: request
            };

            if (this.isDevMode) {
                config.headers = {
                    "oidc-telephonenumber": this.msisdn
                }
            }

            var self = this;
            store.commit("showLoader", true);

            this.axios(config).then(response => {
                
                if (response.data.errorLevel === 0) {
                   console.log("Bundle activated!");

                    var dataLayerObj = {
                        "event": "bundle-activation",
                        "bundle-category": this.selectedBundleGroup.bundleDataLayerEvent,
                        "bundle-id": this.selectedBundle.onlineBundleId,
                        "bundle-name": this.selectedBundle.title,
                        "bundle-value": this.selectedBundle.activationCost.toFixed(2),
                    }
                    handleDataLayer.init();
                    handleDataLayer.pushDataLayer(dataLayerObj);
                                                         
                    //set flag as true in order to invalidate user status service
                    sessionStorage.setItem("invalidateUserStatus",true); 
                    store.commit("showSuccessfulActivation", true);

                } else if(response.data.errorLevel === 1 && response.data.errorCode == 7074){
                    store.commit("hasPaylaterBundle", true);
                    store.commit("paylaterBundle", response.data.overdraft);

                    store.commit("showActivateBundle", false);
                    store.commit("showTopUpPrompt", true);

                } else if(response.data.errorLevel === 1 && response.data.errorCode == 7047){
                    store.commit("hasPaylaterBundle", false);

                    store.commit("showActivateBundle", false);
                    store.commit("showTopUpPrompt", true);
                    
                }else{
    
                    if (self.locale == "en_US") {
                        store.commit("errorTitle","Oops, something went wrong!");
                        store.commit("errorSubtitle", response.data.errorMessage);
                    } else {
                        store.commit("errorTitle","Ουπς, κάτι πήγε στραβά!");
                        store.commit("errorSubtitle", response.data.errorMessage);
                    }
                    
                    store.commit("showFailedActivation", true);
                }
            })
            .catch(error => {
                console.error(error);
                if (self.locale == "en_US") {
                    store.commit("errorTitle","Oops, something went wrong!");
                    store.commit("errorSubtitle", "Παρακαλώ δοκιμάστε αργότερα.");
                } else {
                    store.commit("errorTitle","Ουπς, κάτι πήγε στραβά!");
                    store.commit("errorSubtitle", "Please, try again later!");
                }
                
                store.commit("showFailedActivation", true);

            })
            .finally(() => {
                store.commit("showLoader", false);
            });

      }
  }
};
</script>