<template>
    <div class="container-overlay overlay-modal">
        <div class="modal-wrapper">
            <div class="close-button">
                <span @click="handleCloseModal">
                    <img class="close-cross-button" src="/ext_files/prepaid/f2g/images/cross-close.svg" />
                </span>
            </div>
            <div class="overlay-message">
                <div class="disclaimer-area">
                    <div class="disclaimer-title"> {{ disclaimerTitle }}</div>
                    <div class="disclaimer-subtitle" v-html="dataJumpNotes"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "ModalJumpNotes",
    props: {
        dataJumpNotes: String,
        disclaimerTitle: String
    },
    computed: {
    },
    data() {
        return {};
    },
    methods: {
        handleCloseModal() {
            this.$emit('close-modal');
        },
    }
};
</script>

<!-- styles -->
<style scoped>
    .overlay-message{
      overflow: auto ;
    }
    .disclaimer-area{
      margin-top: 20px;
      display: flex;  
      justify-content: center;
      flex-direction: column;
      align-self: center;
    }
    .disclaimer-title {
      text-align: center;
      font-family: Manrope-Medium;
      font-size: 18px;
      cursor: pointer;
      padding-left: 10px;
    }
    .disclaimer-subtitle{
      text-align: left;
      padding-top: 10px;
      border-top: 1px solid black;
    }
    .container-overlay.overlay-modal{
      z-index: 999999999;
      overflow: auto;
      left: 0;
    }
    .container-overlay.overlay-modal .modal-wrapper{
      height: unset;
    }
  </style>