<template>
  <div class="wrapper-overlay open-overlay">
    <OverlayHeader />
    <div class="container-overlay overlay-form">
        <div class="overlay-message">
            <img src="/ext_files/prepaid/f2g/images/error@2x.png" class="logo-overlay" />
            <div class="title-overlay1">
                {{ words['myf2g.bundles.topup.prompt.title'] }}
            </div>
            <div class="title-overlay2">
                <template v-if="hasPaylaterBundle">
                  {{ words['myf2g.bundles.topup.prompt.subtitle'] }}
                </template>
                <template v-else>
                  {{ words['myf2g.bundles.topup.prompt.subtitle.alternate'] }}
                </template>
                
            </div>

            <div class="overlay-button-container">
              
              <a v-on:click="handleSecondChoiceButton()">
                <div class="button-overlay container-button transparent-btn" style="cursor:pointer;">
                    <template v-if="hasPaylaterBundle">
                      {{ words['myf2g.bundles.topup.prompt.getpaylater'] }}
                    </template>
                    <template v-else>
                      {{ words['myf2g.bundles.topup.prompt.balance'] }}
                    </template>                            
                </div>
              </a> 

              <a :href="goToTopUp">
                <div class="button-overlay container-button blue-btn">
                      {{ words['myf2g.bundles.topup.prompt.topup'] }}                         
                </div>
              </a> 
            </div>
        </div>
    </div>
  </div>
</template>


<script>
import OverlayHeader from "@/components/OverlayHeader.vue"
import store from "@/store/index"

export default {
  name: "TopUpPrompt",
  components: {
    OverlayHeader
  },
  data() {
    return {
      words: {}
    };
  },
  created() {

  }, 
  beforeMount() {
    if ( sessionStorage.getItem("wcs_myf2bundlestopupprompt_"+this.locale) != null ) {
      this.words = JSON.parse(sessionStorage.getItem("wcs_myf2bundlestopupprompt_"+this.locale));
    } else {

      let config = {
            method: 'get',
            url: '/gr/f2g/vueContentService/',
            params: {
                ciKey: 'myf2bundlestopupprompt',
                locale: this.locale,
            }
        }
      
      
      let self = this;
      this.axios(config).then(function(response){
        console.log(response);
        self.words = response.data;
        sessionStorage.setItem("wcs_myf2bundlestopupprompt_"+self.locale, JSON.stringify(self.words));

      }).catch(function(error){
        console.error(error);
      })

    }
  },
  computed: {
    locale() {
        return store.state.locale;
    },
    goToTopUp() {
      if (this.locale == 'en_US') {
        return '/en/f2g/upiresies/online-ananeosi-hronou-omilias/online-ananeosi-hronou-omilias-meso-myf2g-/'
      } else {
        return '/gr/f2g/upiresies/online-ananeosi-hronou-omilias/online-ananeosi-hronou-omilias-meso-myf2g-/'
      }
    },
    hasPaylaterBundle() {
      return store.state.hasPaylaterBundle;
    }
  },
  methods: {
    handleSecondChoiceButton() {
      if (this.hasPaylaterBundle) {

        store.commit("showTopUpPrompt",false);
        store.commit("showPayLaterModal", true);

      } else {
        if (this.locale == "en_US") {
          window.location.href = "/en/myf2g/";
        } else {
          window.location.href = "/gr/myf2g/";
        }
      }
    }
  }
};
</script>