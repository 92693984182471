<template>
  <div class="wrapper-overlay open-overlay">
    <OverlayHeader />
    <div class="container-overlay overlay-form">
        <div class="overlay-message">
            <img src="/ext_files/prepaid/f2g/images/error@2x.png" class="logo-overlay" />
            <div class="title-overlay1">
                {{errorTitle}}
            </div>
            <div class="title-overlay2">
                {{errorSubtitle}}
            </div>

        </div>
    </div>
  </div>
</template>


<script>
import OverlayHeader from "@/components/OverlayHeader.vue"
import store from "@/store/index"

export default {
  name: "FailedActivation",
  components: {
    OverlayHeader
  },
  data() {
    return {
    };
  },
  created() {

  },
  computed: {
    errorTitle() {
      return store.state.errorTitle
    },
    errorSubtitle() {
      return store.state.errorSubtitle
    }
  },
  methods: {
    
  }
};
</script>